var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "expense-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "费用报销",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "我的报销"
    },
    on: {
      "click-right": _vm.toListView
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "expense-content"
  }, [_c('van-cell', {
    staticClass: "expense-cell",
    attrs: {
      "title": "报销类型",
      "center": ""
    }
  }, [_c('van-radio-group', {
    staticClass: "expense-cell__radio",
    attrs: {
      "direction": "horizontal"
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 1
    }
  }, [_vm._v("餐费")]), _c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 9
    }
  }, [_vm._v("其它")])], 1)], 1), _c('van-cell', {
    staticClass: "expense-pic"
  }, [_c('van-uploader', {
    ref: "uploader",
    attrs: {
      "before-read": _vm.beforeRead,
      "capture": _vm.capture
    },
    model: {
      value: _vm.images,
      callback: function callback($$v) {
        _vm.images = $$v;
      },
      expression: "images"
    }
  })], 1), _c('van-field', {
    attrs: {
      "autosize": "",
      "label": "报销内容",
      "type": "textarea",
      "maxlength": "255",
      "placeholder": "请输入内容",
      "show-word-limit": ""
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }), _c('van-field', {
    attrs: {
      "disabled": _vm.type === 1,
      "border": false,
      "type": "number",
      "label": "报销金额(元)",
      "placeholder": "请输入金额"
    },
    model: {
      value: _vm.amount,
      callback: function callback($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  }), _c('van-button', {
    staticClass: "expense-submit-btn",
    attrs: {
      "type": "primary",
      "round": "",
      "block": "",
      "text": "确认提交"
    },
    on: {
      "click": _vm.handleSubmit
    }
  })], 1)]), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.onSelect
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }