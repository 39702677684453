<template>
  <div class="expense-container">
    <my-nav-bar
      title="费用报销"
      left-text="返回"
      left-arrow
      right-text="我的报销"
      @click-right="toListView"
    />
    <div class="app-main-container">
      <div class="expense-content">
        <van-cell class="expense-cell" title="报销类型" center>
          <van-radio-group v-model="type" class="expense-cell__radio" direction="horizontal">
            <van-radio icon-size="0.45rem" :name="1">餐费</van-radio>
            <van-radio icon-size="0.45rem" :name="9">其它</van-radio>
          </van-radio-group>
        </van-cell>
        <van-cell class="expense-pic">
          <!-- <van-grid :column-num="3" square :border="false" :gutter="10">
            <van-grid-item v-for="(image, index) in images" :key="index">
              <div class="van-uploader__preview-delete" @click="deleteImage(index)">
                <i class="van-icon van-icon-cross van-uploader__preview-delete-icon" />
              </div>
              <van-image width="2rem" height="2.1rem" :src="image.content" fit="cover" />
            </van-grid-item>
            <van-grid-item v-if="images.length < 6" @click="showActionSheet = true">
              <div class="expense-upload">
                <van-image width="0.9rem" :src="require('@/assets/images/tianjiatupian.png')" />
                <div class="expense-upload__text">上传图片</div>
              </div>
            </van-grid-item>
          </van-grid> -->
          <!-- 手动调用 -->
          <van-uploader
            ref="uploader"
            v-model="images"
            :before-read="beforeRead"
            :capture="capture"
          />
        </van-cell>
        <van-field
          v-model="description"
          autosize
          label="报销内容"
          type="textarea"
          maxlength="255"
          placeholder="请输入内容"
          show-word-limit
        />
        <van-field v-model="amount" :disabled="type === 1" :border="false" type="number" label="报销金额(元)" placeholder="请输入金额" />
        <van-button class="expense-submit-btn" type="primary" round block text="确认提交" @click="handleSubmit" />
      </div>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import expenseApi from '@/api/expense'
import myNavBar from '@/components/my-nav-bar'
import Compressor from 'compressorjs'
export default {
  name: 'Expense',
  components: { myNavBar },
  data() {
    return {
      type: 1,
      description: '',
      amount: '15',
      images: [],
      showActionSheet: false,
      capture: null,
      actions: [
        { name: '拍照', value: 1 },
        { name: '相册', value: 2 }
      ],
      uploadImages: []
    }
  },
  watch: {
    type(newVal) {
      if (newVal === 1) { this.amount = '15' } else { this.amount = '' }
    }
  },
  methods: {
    onSelect(action) {
      if (action.value === 1) { this.capture = 'camera' } else { this.capture = null }
      this.showActionSheet = false
      this.$nextTick(() => {
        this.$refs.uploader.chooseFile()
      })
    },
    beforeRead(file) {
      return new Promise((resole, reject) => {
        new Compressor(file, {
          convertTypes: ['image/png', 'image/webp'],
          convertSize: 100000,
          quality: 0.2,
          success: (newFile) => {
            this.beginLoad()
            expenseApi.upload(newFile).then(res => {
              this.endLoad()
              this.uploadImages.push(res.data.image)
              resole()
            }).catch(() => {
              reject()
            })
          }
        })
      })
    },
    deleteImage(index) {
      this.images.splice(index, 1)
      this.uploadImages.splice(index, 1)
    },
    handleSubmit() {
      if (this.uploadImages.length === 0) {
        this.fail('请上传凭证或发票')
        return
      }
      if (this.type === 9 && this.description.length === 0) {
        this.fail('请填写报销内容')
        return
      }

      if (Number(this.amount) <= 0) {
        this.fail('报销金额必须大于0')
        return
      }

      const data = {
        type: this.type,
        images: this.uploadImages,
        description: this.description,
        amount: this.amount
      }
      this.beginLoad()
      expenseApi.add(data).then(res => {
        this.success(res.msg)
        this.$router.go(-1)
      })
    },
    toListView() {
      this.$router.push('/expense-list')
    }
  }
}
</script>

<style lang="scss" scoped>
  .expense-content {
    padding: 15px;
  }
  .expense-cell {
    &__radio{
      justify-content: flex-end;
    }
  }
  .expense-pic {
    padding: 15px;
    background-color: #fff;
    .van-grid-item {
      &__content {
        background-color: #F7F7F7;
        border-radius: 4px;
      }
    }
  }
  .expense-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__text {
      padding-top: 5px;
      font-size: 12px;
      color: #646566;
    }
  }
  .expense-submit-btn {
    margin-top: 10px;
  }
</style>
